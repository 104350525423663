<template>
  <div class="w-50 mx-auto">
    <div data-v-7aa56586="" class="rowTitle">
      <h4 data-v-7aa56586="" style="font-family: TTnorms; color: rgb(0, 0, 0); margin-top: 9px; font-size: 2vw;">DSG
        <hr data-v-7aa56586="" style="width: 60%; border-top: 1px solid rgb(0, 0, 0); margin-top: 14px;">
      </h4>
    </div>

    <div style="margin-bottom: 1.5vw;" class="text-center generalStyle product_d_info">
      <p
        style='font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;'>
        <span data-v-7aa56586="" class="wordpressRed">ADDITIONAL INFORMATION</span></p>
    </div>

    <div class="input-group-addon d-flex" :class="item.class">
      <div class="flex-custom">
        <i class="material-icons-outlined multiple_choice_option_select"
           :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
        <span>{{ item.name }}</span>
      </div>
      <DropDownSelect
        class="select_drop"
        :items="item.options"
        v-model="item.option_selected"
        :preselected-options="{values: [], emitNull: true}"
        :ref="`${item.location}-${item.id}`"
        @change="selectItem"
      />
    </div>
    <div :class="item2.disabled? 'disabled' :''">
      <div class="input-group-addon d-flex" :class="item2.class">
        <div class="flex-custom">
          <i class="material-icons-outlined multiple_choice_option_select"
             :class="{'arrow': item2.icon === 'add_box'}">{{ item2.icon }}</i>
          <span>{{ item2.name }}</span>
        </div>
        <DropDownSelect
          class="select_drop"
          :disabled="item2.disabled"
          :items="item2.options"
          v-model="item2.option_selected"
          :preselected-options="{values: item2.option_preselected, emitNull: true}"
          ref="replaced"
          @change="selectItem2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropDownSelect from "@/components/common/DropDownSelect";

export default {
  name: "OrigFile",
  components: {DropDownSelect},
  data: () => ({
    item: {
      name: 'TVS hardware installed?',
      options: [{description: "Yes", id: "1"}, {description: "No", id: "2"}],
      option_selected: null,
      option_preselected: [],
      id: "original_file",
      location: 'dsgHardwareXoptions',
      type: 'hardware_modification',
      icon: 'add'
    },
    item2: {
      name: 'Replaced hardware',
      options: [
        {description: "Mechatronic", id: "1"},
        {description: "Gearbox", id: "2"},
        {description: "Other", id: "3"}
      ],
      option_selected: null,
      option_preselected: [],
      location: "orig_file",
      id: "2",
      icon: 'add',
      disabled: true
    },
    option_result: {
      description: "",
    },
  }),
  methods: {
    selectItem(value) {
      this.item.icon = 'check';
      this.item.option_selected = value;
      this.item2.disabled = false;
      this.$emit("price", value === '1' ? 0 : 195);
      this.item2.option_selected = null;
      this.$refs.replaced.setPreselected();
      this.item2.icon = 'add';
      this.unReady();
    },
    async selectItem2(value) {
      this.item2.icon = 'check';
      this.item2.option_selected = value;
      if (this.item.option_selected === '2' && value === '2') {
        const newGearbox = await this.requestNewGearbox();
        if (newGearbox !== false && newGearbox !== '') {
          const description = this.item2.options.find(el => el.id === value).description;
          this.option_result.description = `${description} / New Gearbox ${newGearbox}`;
          this.ready();
        } else {
          this.$refs.replaced.setPreselected();
          this.item2.icon = 'add';
        }
      } else if (this.item.option_selected === '1') {
        const invoice_number = await this.requestInvoiceNumber();
        if (invoice_number !== false && invoice_number != '' && invoice_number.length >= 5) {
          const description = this.item2.options.find(el => el.id === value).description;
          this.option_result.description = `${description} / Invoice Nº ${invoice_number}`;
          this.ready();
        } else {
          this.$refs.replaced.setPreselected();
          this.item2.icon = 'add';
        }
      } else {
        this.option_result.description = this.item2.options.find(el => el.id === value).description;
        this.ready();
      }
    },
    async requestInvoiceNumber() {
      const value = await this.$swal({
        title: 'INVOICE NUMBER',
        text: 'ADD INVOICE NUMBER',
        input: 'text',
        inputPlaceholder: 'INVOICE NUMBER',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        inputValidator: (value) => {
          if (!value) {
            return 'Value is required'
          }else if (value.length <5){
            return 'Must be equal to or greater than 5 characters.'
          }
        }
      });
      if (value.isConfirmed) {
        return value.value;
      } else {
        return false;
      }
    },
    async requestNewGearbox() {
      const value = await this.$swal({
        title: 'NEW GEARBOX CODE',
        text: 'ADD NEW GEARBOX CODE',
        input: 'text',
        inputPlaceholder: 'NEW GEARBOX CODE',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        inputValidator: (value) => {
          if (!value) {
            return 'Value is required'
          }
        }
      });
      if (value.isConfirmed) {
        return value.value;
      } else {
        return false;
      }
    },
    unReady() {
      this.$emit("ready", this.item);
    },
    ready() {
      let item = JSON.parse(JSON.stringify(this.item));
      let yesOrNot = this.item.options.find(el => el.id === this.item.option_selected).description;
      item.name = `${item.name} / ${yesOrNot}`;
      item.options = [this.option_result];
      this.$emit("ready", item);
    },
    setAsError() {
      if (this.item.option_selected === null) {
        this.item.class = 'color-red';
      }
      if (!this.item2.disabled && this.item2.option_selected === null) {
        this.item2.class = 'color-red';
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";

.product_d_info {
  padding: 30px;
  background: #ffffff;
  margin-bottom: 58px;
}
</style>
