<template>
  <div class="with-custom-checkbox" style="background-color: white;" v-if="params">
    <div class="container">
      <div v-if="currentDsg && currentDsg.id ==='original_file'" class="mb-3">
        <orig-file @price="$emit('replace-price', $event)" @ready="ready" ref="org_file"/>
        <hr>
      </div>
      <template v-else>
        <div v-if="params.type === 'ECU' || params.type === 'ECU + DSG'">
          <div class="rowTitle">
            <h4 style="font-family: 'TTnorms'; color: rgb(0, 0, 0); margin-top: 9px; font-size: 2vw;">ECU
              <hr style="width: 60%; border-top: 1px solid rgb(0, 0, 0); margin-top: 14px;"/>
            </h4>
          </div>
          <div class="generalStyle product_d_info" v-if="[].concat(hardwareMandatory, hardware).length">
            <div class="row">
              <div class="col-12">
                <div class="product_d_inner">
                  <div class="product_info_content">
                    <b-row style="min-height: 15vw;">
                      <div style="position: absolute; top: 50%;">
                        <h1 style="position: relative;">
                          <span class="verticalText">
                            &nbsp;HARDWARE
                          </span>
                        </h1>
                      </div>
                      <b-col class="p-0" cols="5" offset="1">
                        <div style="margin-bottom: 1.5vw;">
                          <p
                            style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                            <span class="wordpressRed">REQUIRED MODIFICATIONS</span>
                          </p>
                        </div>
                        <div class="option-list-container">
                          <div v-if="hardwareMandatory.length">
                            <div v-for="(item) of hardwareMandatory"
                                 v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                 :content="item.description"
                                 :class="{'disabled': item.disabled}">
                              <div>
                                <template v-if="typeof item.options !== 'string' && item.options.length">
                                  <div class="input-group-addon d-flex" :class="item.class">
                                    <div class="flex-custom">
                                      <i class="material-icons-outlined multiple multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}</span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: false}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </template>
                                <template v-else>
                                  <b-checkbox disabled :checked="true">{{
                                      item.name
                                    }}
                                  </b-checkbox>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div
                              v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }">
                              <span>
                                <template>
                                  <b-checkbox disabled :checked="true">Stock Engine Hardware</b-checkbox>
                                </template>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col class="p-0" cols="5" offset="1">
                        <div
                          style="margin-bottom: 1.5vw;">
                          <p
                            style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                            <span class="wordpressRed"> ADDITIONAL MODIFICATIONS</span>
                          </p>
                          <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                        </div>
                        <div class="option-list-container">
                          <div v-for="(item, index) in hardware"
                               v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                               :content="item.description">
                            <div v-if="item.options.length && item.options !='Boolean'">
                              <div class="input-group-addon"
                                   style="display: flex; ">
                                <div class="d-flex">
                                  <i class="material-icons-outlined multiple multiple_choice_option_select"
                                     :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                  <span>{{ item.name }}:</span>
                                </div>
                                <DropDownSelect
                                  :disabled="item.disabled"
                                  class="select_drop"
                                  :items="item.options"
                                  v-model="item.option_selected"
                                  :preselected-options="{values: item.option_preselected, emitNull: true}"
                                  :ref="`${item.location}-${item.id}`"
                                  @change="selectSecondaryOption(item, $event, index)"
                                />
                              </div>
                            </div>
                            <div v-else class="input-group-addon" :class="item.class">
                              <b-checkbox @change="determineControl(item)" :checked="item.checked">{{
                                  item.name
                                }}
                              </b-checkbox>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
            <hr class="line-sep"/>
          </div>
          <!-- HARDWARE MODIFICATIONS -->
          <div class="generalStyle product_d_info" v-if="sofwareMandatory.length || sofware.length">
            <div class="row">
              <div class="col-12">
                <div class="product_d_inner">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" role="tabpanel">
                      <div class="product_info_content">
                        <b-row style="min-height: 15vw;">
                          <div style="position: absolute; top: 55%;">
                            <h1 style="position: relative;">
                          <span class="verticalText">
                            &nbsp;SOFTWARE
                          </span>
                            </h1>
                          </div>
                          <b-col class="p-0" cols="5" offset="1">
                            <div style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">FEATURES</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <ul>
                                <li v-for="(item, index) in sofwareMandatory"
                                    v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                    :content="item.description">
                              <span>
                                <template v-if="typeof item.options !== 'string' && item.options.length">
                                  <div class="input-group-addon d-flex" :class="item.class">
                                    <div class="flex-custom">
                                      <i class="material-icons-outlined multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}:</span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: false}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"/>
                                  </div>
                                </template>
                                <template v-else>
                                  <b-checkbox disabled :checked="true">{{
                                      item.name
                                    }}</b-checkbox>
                                </template>
                              </span>
                                </li>
                              </ul>
                            </div>
                          </b-col>
                          <b-col class="p-0" cols="5" offset="1">
                            <div
                              style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">SELECTABLE OPTIONS</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <div v-for="(item, index) in sofware" style=""
                                   v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                   :content="item.description">
                                <div v-if="typeof item.options !== 'string' && item.options.length">
                                  <div
                                    class="input-group-addon"
                                    :class="item.class" style="display: flex; ">
                                    <div class="d-flex">
                                      <i class="material-icons-outlined multiple multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}: </span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      default-text="Select"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: true}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="input-group-addon">
                                    <b-checkbox @change="determineControl(item)" v-model="item.checked"
                                                :disabled="item.disabled">{{ item.name }}
                                    </b-checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="line-sep"/>
        </div>
        <div
          v-if="(params.type === 'DSG' && currentDsg && currentDsg.options != null) || (params.type === 'ECU + DSG' && currentDsg && currentDsg.options != null)">
          <div class="rowTitle">
            <h4 style="font-family: 'TTnorms'; color: rgb(0, 0, 0); margin-top: 9px; font-size: 2vw;">DSG
              <hr style="width: 60%; border-top: 1px solid rgb(0, 0, 0); margin-top: 14px;"/>
            </h4>
          </div>
          <div class="generalStyle product_d_info" v-if="preSelectedDsgOptions.length || dsgOptions.length">
            <div class="row">
              <div class="col-12">
                <div class="product_d_inner">
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <div class="product_info_content">
                        <b-row style="min-height: 15vw;" class="position-relative">
                          <div style="position: absolute; top: 50%;">
                            <h1 style="position: relative;">
                            <span class="verticalText">
                              SOFTWARE
                            </span>
                            </h1>
                          </div>
                          <b-col class="p-0" cols="5" offset="1">
                            <div style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">FEATURES</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <ul>
                                <li v-for="(item) in preSelectedDsgOptions"
                                    v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                    :content="item.description">
                              <span>
                                <template v-if="typeof item.options != 'string' && item.options.length">
                                  <div
                                    class="input-group-addon d-flex" :class="item.class">
                                    <div class="d-flex">
                                      <i class="material-icons-outlined multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}: </span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: false}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </template>
                                <template v-else>
                                  <b-checkbox disabled :checked="true" :class="{'disabled-checkbox': item.disabled}">{{
                                      item.name
                                    }}</b-checkbox>
                                </template>
                              </span>
                                </li>
                              </ul>
                            </div>

                          </b-col>
                          <!--  -->
                          <b-col class="p-0" cols="5" offset="1">
                            <div
                              style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">SELECTABLE OPTIONS</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <div v-for="(item, index) of dsgOptions" :key="index" :data-option-id="item.id"
                                   v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                   :content="item.description"
                                   :class="{'disabled': item.disabled}">
                                <div v-if="typeof item.options !== 'string' && item.options.length">
                                  <div class="input-group-addon d-flex" :class="item.class">
                                    <div class="flex-custom">
                                      <i class="material-icons-outlined multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}</span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: true}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="input-group-addon">
                                    <b-checkbox @change="determineControl(item)" v-model="item.checked"
                                                :disabled="item.disabled">{{ item.name }}
                                    </b-checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="generalStyle product_d_info" v-if="[].concat(dsgHardwareXoptions,dsgHardwareOptions).length">
            <hr class="line-sep"/>
            <div class="row">
              <div class="col-12">
                <div class="product_d_inner">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" role="tabpanel">
                      <div class="product_info_content">
                        <b-row style="min-height: 15vw;" class="position-relative">
                          <div style="position: absolute; top: 50%;">
                            <h1 style="position: relative;">
                            <span class="verticalText">
                              HARDWARE
                            </span>
                            </h1>
                          </div>
                          <b-col class="p-0" cols="5" offset="1">
                            <div style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">REQUIRED MODIFICATIONS</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <div v-if="dsgHardwareXoptions.length">
                                <div v-for="(item) in dsgHardwareXoptions"
                                     v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                     :content="item.description">
                              <span>
                                <template v-if="typeof item.options != 'string' && item.options.length">
                                  <div
                                    class="input-group-addon d-flex" :class="item.class">
                                    <div class="d-flex">
                                      <i class="material-icons-outlined multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}: </span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: false}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </template>
                                <template v-else>
                                  <b-checkbox disabled :checked="true">{{
                                      item.name
                                    }}</b-checkbox>
                                </template>
                              </span>
                                </div>
                              </div>
                              <ul v-else>
                                <li
                                  v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }">
                              <span>
                                <template>
                                  <b-checkbox disabled :checked="true">Stock DSG Hardware</b-checkbox>
                                </template>
                              </span>
                                </li>
                              </ul>
                            </div>

                          </b-col>
                          <!--  -->
                          <b-col class="p-0" cols="5" offset="1">
                            <div
                              style="margin-bottom: 1.5vw;">
                              <p
                                style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                                <span class="wordpressRed">ADDITIONAL MODIFICATIONS</span>
                              </p>
                              <!-- <hr style="width: 3.5vw; border-top: 1px solid black; margin-top: 4vw; position: absolute;"/> -->
                            </div>
                            <div class="option-list-container">
                              <div v-for="(item, index) of dsgHardwareOptions"
                                   :key="index"
                                   v-tippy="{ placement : 'top',  arrow: true, onShow: () => item? item.description !== null : false }"
                                   :content="item.description">
                                <div v-if="typeof item.options !== 'string' && item.options.length">
                                  <div class="input-group-addon d-flex" :class="item.class">
                                    <div class="flex-custom">
                                      <i class="material-icons-outlined multiple_choice_option_select"
                                         :class="{'arrow': item.icon === 'add_box'}">{{ item.icon }}</i>
                                      <span>{{ item.name }}</span>
                                    </div>
                                    <DropDownSelect
                                      :disabled="item.disabled"
                                      class="select_drop"
                                      :items="item.options"
                                      v-model="item.option_selected"
                                      :preselected-options="{values: item.option_preselected, emitNull: true}"
                                      :ref="`${item.location}-${item.id}`"
                                      @change="selectSecondaryOption(item, $event, index)"
                                    />
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="input-group-addon">
                                    <b-checkbox @change="determineControl(item)" :checked="item.checked">{{
                                        item.name
                                      }}
                                    </b-checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <b-modal v-if="showNewProduct">
      <template #modal-header>
        <h6>Add a new product</h6>
      </template>
      <b-modal-body>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Name: </span>
          </div>
          <b-form-input v-model="productDescription" class="form-control" placeholder="Enter product name or value"
                        type="text"></b-form-input>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Description: </span>
          </div>
          <b-form-input v-model="productExplanation" class="form-control" placeholder="Enter product description"
                        type="text"></b-form-input>
        </div>
      </b-modal-body>
      <b-modal-footer>
            <span style="display: flex; align-items: center; justify-content: center;">
              <b-btn class="buttonNormal" @click="addproduct()">Add</b-btn>
            </span>
      </b-modal-footer>
    </b-modal>
    <b-modal id="addProduct" ref="addProduct" centered no-close-on-backdrop @close="handleClose" no-enforce-focus>
      <template #modal-header>
        <b-modal-title>Add a new product</b-modal-title>
      </template>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Name: </span>
        </div>
        <b-form-input v-model="productDescription" class="form-control" placeholder="Enter product name or value"
                      type="text"/>
        <b-form-invalid-feedback class="text-right" v-if="showAddProductError">
          Field is required
        </b-form-invalid-feedback>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Description: </span>
        </div>
        <b-form-input v-model="productExplanation" class="form-control" placeholder="Enter product description"
                      type="text"/>
      </div>
      <template #modal-footer>
            <span style="color: white !important;">
              <b-button variant="danger" style="text-transform: uppercase" class="mx-1"
                        @click="handleClose()">CANCEL</b-button>
              <b-button variant="primary" @click="addproduct()">ADD</b-button>
            </span>
      </template>
    </b-modal>
    <slot/>
  </div>
</template>

<script>
import {get, post} from '@/services/api';
import {processDataSoftwareTunning} from '@/views/dashboard/create-software-order/processDataSoftwareTunning';
import {processDataDSGTunning} from '@/views/dashboard/create-software-order/processDataDSGTunning';
import {processCombos} from '@/views/dashboard/create-software-order/proccessCombos';
import DropDownSelect from '@/components/common/DropDownSelect';
import {createProduct, dsgCreateProduct} from "@/services/endpoint.json";
import {mapGetters} from "vuex";
import {onRequestPrice} from "@/constants";
import OrigFile from "@/views/dashboard/create-software-order/options/OrigFile";

function initial() {
  return {
    items: [],
    sofware: [],
    sofwareMandatory: [],
    sofwareRecomended: [],
    hardware: [],
    hardwareMandatory: [],
    hardwareRecomended: [],
    dsgHardwareOptions: [],
    dsgOptions: [],
    dsgHardwareXoptions: [],
    preSelectedDsgOptions: [],

    currentEcu: null,
    currentDsg: null,
    order: null,

    params: null,
    vehicleDataServer: null,
    vehicle: {},
    showAddProductError: false,
    productDescription: null,
    productExplanation: null
  }
}

export default {
  name: 'OptionsEditor',
  components: {OrigFile, DropDownSelect},
  props: {
    cleanDSGAndECU:{
      default:false,
      required: false,
      type:Boolean
    },
    vehicleDSG:{
      required:false,
      default: null,
      type: Object
    },
    value: Array,
    vehicleId: Number,
    showPriceAlerts: {
      type: Boolean,
      default: false
    },
    saved_price: {
      type: Number,
      default: 0
    },
  },
  data: function () {
    return initial()
  },

  watch : {
    cleanDSGAndECU : function (value) {
      this.currentDsg = null
      this.currentEcu= null,
      this.params=null,
      this.cleanDSGAndECU = false
    }
  },

  methods: {
    format() {
      switch (this.params.type) {
        case 'ECU':
          this.currentEcu = this.vehicleDataServer.ecu.find(el => el.id === this.params.id);
          this.formatEcu();
          this.items = JSON.parse(JSON.stringify([].concat(this.sofwareMandatory, this.hardwareMandatory)));
          break;
        case 'ECU + DSG':
          const combo = this.vehicleDataServer.combos.find(el => el.id === this.params.id);
          this.currentEcu = combo.ecuObject;
          this.currentDsg = combo.dsgObject;
          this.formatEcu();
          this.formatDSG();
          this.items = JSON.parse(JSON.stringify([].concat(this.sofwareMandatory, this.hardwareMandatory, this.preSelectedDsgOptions, this.dsgHardwareXoptions)));
          break;
        case 'DSG':
          this.currentDsg = this.vehicleDataServer.tcu.find(el => el.id === this.params.id);
          this.formatDSG();
          this.items = JSON.parse(JSON.stringify(this.preSelectedDsgOptions.concat(this.dsgHardwareXoptions)));
      }
      this.checkChoices()
      this.emitChange()
    },
    formatDSG() {
      this.currentDsg.hardwareOptions.forEach(item => {
        item.icon = 'add';
        item.dest = 'DSG';
        if (item.add_new_one) {
          item.options.push({
            id: 'addProduct',
            description: 'Add new product or value',
            class: 'font-italic'
          });
        }
        if (item.default_option) {
          item.option_preselected = JSON.parse(item.default_option);
        }
        if (item.required) {
          item.location = 'dsgHardwareXoptions';
          item.option_preselected = [];
          this.dsgHardwareXoptions.push(item);
        } else {
          item.location = 'dsgHardwareOptions';
          this.dsgHardwareOptions.push(item);
          if(this.DsgConversionAndFlexStatus!==true){
            this.dsgHardwareOptions = this.removeOptionWithId75(this.dsgHardwareOptions);
          }
        }
      });
      this.currentDsg.options.forEach((item) => {
        item.icon = 'add';
        item.dest = 'DSG';
        if (item.add_new_one) {
          item.options.push({
            id: 'addProduct',
            description: 'Add new product or value',
            class: 'font-italic'
          });
        }
        if (item) {
          if (item.default_option) {
            item.option_preselected = JSON.parse(item.default_option);
          }
          if (item.required === true) {
            item.location = 'preSelectedDsgOptions';
            item.option_preselected = [];
            this.preSelectedDsgOptions.push(item);
          } else {
            item.location = 'dsgOptions';
            this.dsgOptions.push(item);
          }
        }
      });
    },
 removeOptionWithId75(arr) {
  return arr.map(obj => {
    if (obj.options) {
      const updatedOptions = obj.options.filter(option => option.id !== 75);
      return { ...obj, options: updatedOptions};
    }
    return obj;
  });
},

    formatEcu() {
      this.currentEcu.options.forEach((item) => {
        if (item) {
          item.dest = 'ECU';
          if (item.add_new_one) {
            item.options.push({
              id: 'addProduct',
              description: 'Add new product or value',
              class: 'font-italic'
            });
          }
          if (item.default_option) {
            item.option_preselected = JSON.parse(item.default_option);
          }
          if (Array.isArray(item.options) && item.options.length) {
            item.icon = 'add';
          }

          if (item.type.toString() === 'engine_software' && item.required === false) {
            item.location = 'sofware';
            if (item.id === 12) {
              this.sofware.unshift(item);
            } else {
              this.sofware.push(item);
            }
          }
          if (item.type.toString() === 'engine_software' && item.required === true) {
            item.location = 'sofwareMandatory';
            item.option_preselected = [];
            if (item.id === 12) {
              this.sofwareMandatory.unshift(item);
            } else {
              this.sofwareMandatory.push(item);
            }
          }
          if (item.type.toString() === 'hardware_modification' && item.required === false) {
            item.location = 'hardware';
            this.hardware.push(item);
          }
          if (item.type.toString() === 'hardware_modification' && item.required === true) {
            item.location = 'hardwareMandatory';
            item.option_preselected = [];
            this.hardwareMandatory.push(item);
          }
        }
      });
    },
    async selectSecondaryOption(item, option, index, showExtra = true) {
      const saveditem = JSON.parse(JSON.stringify(item));
      if (item.required) {
        saveditem.class = '';
        if (!option) {
          saveditem.class = 'color-red';
        }
      }
      if (Boolean(item.save_price)) {
        this.$emit('replace-price', item.save_price);
      }
      if (!isNaN(option) && option) {
        item.icon = 'check';
        const optionSlected = item.options.find(el => el.id == option);
        item.option_selected = optionSlected.id;
        const copyItem = JSON.parse(JSON.stringify(item));
        copyItem.options = [optionSlected];
        let indexOnItems = this.items.findIndex(el => {
          return (el.id === item.id && el.location === item.location);
        });
        let confirmAdd = true;
        if (optionSlected.extradata) {
          optionSlected.extraValue = '';
          optionSlected.extradata = (typeof optionSlected.extradata == 'string') ? JSON.parse(optionSlected.extradata) : optionSlected.extradata;
          let extraValue = optionSlected.extraValue ? optionSlected.extraValue : false
          if (showExtra) {
            extraValue = await this.showExtraInput(optionSlected.extradata);
          }
          if (extraValue !== false) {
            optionSlected.extraValue = extraValue;
            const optionSelected = optionSlected;
            const result = extraValue;
            let text = optionSlected.extradata.on_result.description;
            const regexp = /\{(.*?)\}/g
            const found = {};
            let array1;
            while ((array1 = regexp.exec(text)) !== null) {
              found[array1[1]] = array1[0]
            }
            Object.keys(found).forEach(value => {
              let [object, field] = value.split('.');
              text = text.replace(found[value], eval(`${object}[field]`))
            })
            optionSlected.description = text;
            item.save_price = this.saved_price;
            this.$emit('update-price', 9999);
            this.$refs[`${item.location}-${item.id}`][0].textSelected = `${text}`;
          } else {
            item.icon = 'add';
            this.$refs[`${item.location}-${item.id}`][0].setPreselected();
            confirmAdd = false;
            return;
          }
        }
        if (!!copyItem.new_price) {
          item.save_price = this.saved_price;
          if (isNaN(copyItem.new_price)) {
            this.$emit('replace-price', onRequestPrice);
            item.save_price = onRequestPrice * -1
          } else {
            this.$emit('replace-price', this.userCountryPrice(copyItem.new_price));
            item.save_price = this.userCountryPrice(copyItem.new_price) * -1
          }
        }
        if (!!optionSlected.new_price) {
          if (isNaN(optionSlected.new_price)) {
            this.$emit('replace-price', onRequestPrice);
            item.save_price = onRequestPrice * -1
          } else {
            this.$emit('replace-price', this.userCountryPrice(optionSlected.new_price));
            item.save_price = this.userCountryPrice(optionSlected.new_price) * -1
          }
        }
        if (indexOnItems > -1 && confirmAdd) {
          this.items = JSON.parse(JSON.stringify(this.items.map(el => {
            if (el.id === item.id && el.location == item.location) {
              el.options = [optionSlected];
              return el;
            }
            return el;
          })));
        } else if (confirmAdd) {
          this.items.push(copyItem);
        }
      } else if (!option) {
        item.icon = 'add';
        let indexOnItems = this.items.findIndex(el => {
          return (el.id === item.id && el.location === item.location);
        });
        if (indexOnItems > -1) {
          this.items.splice(indexOnItems, 1);
        }
        if (Boolean(item.save_price)) {
          this.$emit('replace-price', item.save_price);
        }
      } else if (option === 'addProduct') {
        this.$refs.addProduct.show();
        this.showAddProductError = false;
        this.currentItem = item;
      }
      if (item.options.length !== saveditem.options.length) {
        item.options = saveditem.options;
      }
      this.$set(this[item.location], index, item);
      this.checkChoices();
      this.emitChange();
    },
    async determineControl(item) {
      const indexLocation = this[item.location].find((el) => el.id === item.id);
      let option = this.items.findIndex(el => el.id == item.id && el.location === item.location);
      if (option > -1) {
        item.checked = false;
        this.items.splice(option, 1);
        if (item.new_price) {
          this.$emit('update-price', item.new_price * -1);
        }
      } else {
        item.checked = true;
        if (item.new_price && this.showPriceAlerts) {
          await new Promise(resolve => {
            this.$swal({
              title: 'Information',
              icon: 'question',
              text: `${item.name} is available for an extra fee of ${item.new_price} credits.`,
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000',
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              showCancelButton: true
            }).then((value) => {
              if (value.isConfirmed) {
                this.items.push(item);
                this.$emit('update-price', item.new_price);
              } else {
                item.checked = false;
              }
              resolve(true)
            })
          })
        } else {
          this.items.push(item);
        }
      }
      this.$set(this[item.location], indexLocation, item);
      this.checkChoices();
      this.emitChange();
    },
    setStatusFromPreOrder() {
      if (this.order) {
        this.order.items.forEach(item => {
          let itemInComponent = [].concat(this.sofware, this.hardware, this.sofwareMandatory, this.hardwareMandatory, this.preSelectedDsgOptions, this.dsgOptions, this.dsgHardwareXoptions, this.dsgHardwareOptions)
            .find(el => el.id === item.id && el.location === item.location);
          if (itemInComponent) {
            let index = this[itemInComponent.location].findIndex(el => el.id === itemInComponent.id);
            if (Array.isArray(itemInComponent.options) && itemInComponent.options.length) {
              this.selectSecondaryOption(item, item.options[0].id, index, false);
              itemInComponent.icon = 'check';
              itemInComponent.option_selected = item.options[0].id;
            } else {
              if (['sofware', 'hardware', 'dsgOptions', 'dsgHardwareOptions'].includes(itemInComponent.location)) {
                itemInComponent.checked = true;
                this.determineControl(item);
              }
            }
            this.$set(this[itemInComponent.location], index, itemInComponent);
          }
        });
        this.checkChoices();
      }
    },
    checkChoices() {
      setTimeout(() => {
        this.dsgOptions = this.dsgOptions.map((option) => this.manegeChoiseRestriction(option));
        this.preSelectedDsgOptions = this.preSelectedDsgOptions.map((option) => this.manegeChoiseRestriction(option));

        this.hardwareMandatory = this.hardwareMandatory.map(option => this.manegeChoiseRestriction(option))
        this.hardware = this.hardware.map(option => this.manegeChoiseRestriction(option))
      }, 100);
    },
    manegeChoiseRestriction(option) {
      if (option.choices) {
        if (option.choices.enable) {
          let nowDisable = this.comproveIfCumple(option, option.choices.enable, option.choices.model_restriction, false, option.location);
          if (nowDisable) {
            this.removeFromSelected(option);
          } else if (option.required && !this.items.find(el => el.option_id == option.option_id && el.location == option.location)) {
            this.items.push(option);
          }
          option.disabled = nowDisable;
        }
        if (option.choices.disable) {
          let nowDisable = this.comproveIfCumple(option, option.choices.disable, option.choices.model_restriction, true, option.location);
          if (nowDisable) {
            this.removeFromSelected(option);
          } else if (option.required && !this.items.find(el => el.option_id == option.option_id && el.location == option.location)) {
            this.items.push(option);
          }
          option.disabled = nowDisable;
        }
      }
      return option;
    },
    removeFromSelected(option) {
      let index = this.items.findIndex(el => el.option_id == option.option_id && el.location == option.location);
      if (index > -1) {
        this.items.splice(index, 1);
        option.icon = 'add';
        if (Array.isArray(option.options) && option.options.length) {
          this.resetOption(option);
        }
      }
    },
    comproveIfCumple(option, restriction, model_restriction, returnValue, location) {
      let arrayOptions = [].concat(this.dsgOptions, this.preSelectedDsgOptions);
      if (['hardware', 'hardwareMandatory'].includes(location)) {
        arrayOptions = [].concat(this.hardware, this.hardwareMandatory)
      }
      const optionFound = arrayOptions
        .find(el => el.option_id == restriction.option_id);
      if (model_restriction === undefined || model_restriction.includes(this.vehicle.dsg)) {
        if (optionFound && restriction.type_rel == 'boolean') {
          return optionFound.checked.toString() == restriction.value_expected ? returnValue : !returnValue;
        } else {
          return !returnValue;
        }
      } else {
        return returnValue;
      }
    },
    setParams(params) {
      this.params = params;
      this.format()
    },
    reset() {
      Object.assign(this.$data, initial());
    },
    setOrder(order) {
      const items = JSON.parse(JSON.stringify(order.description.item));
      this.order = {
        id: order.id,
        items,
        type: order.type,
      };
      this.params = {
        id: order.stage,
        type: order.type,
      }
      this.format();
      items.forEach(el => {
        if (Array.isArray(el.options) && el.options.length) {
          if (el.options[0].description.includes('NEW')) {
            const extraOption = el.options[0];
            const obj = this[el.location].find(inComponent => inComponent.id === el.id);
            obj.options.push(extraOption);
          }
        }
      })
      this.$nextTick(() => {
        setTimeout(() => {
          this.setStatusFromPreOrder();
        }, 100)
      })
    },
    setOptions(params, items) {
      this.params = params;
      this.order = {items};
      this.format();
      this.$nextTick(() => {
        setTimeout(() => {
          this.setStatusFromPreOrder();
        }, 100)
      })
    },
    async loadVehicle(vehicleId = null, dsgFamily = null) {
      if(dsgFamily == null && this.vehicleDSG !== null ){
        dsgFamily = this.vehicleDSG.dsg_family
      }
      if (vehicleId !== null) {
        this.vehicleId = vehicleId
      }
      const {data} = await get(`site/${this.vehicleId}?dsg=${dsgFamily}`);
      if (data.message.dsg) {
        this.vehicle.dsg = data.message.dsg.DSG.split('_')[0];
      }
      const ecu = processDataSoftwareTunning(data.message.tuning);
      const tcu = processDataDSGTunning(data.message.dsg);
      this.vehicleDataServer = {
        ecu,
        tcu,
        combos: processCombos(data.message.combos, ecu, tcu).combos,
      };
      return data.message;
    },
    /*
    * @param data: {ecu: array, tcu: array, combos: array}
     */
    setDataForOptions(data) {
      this.vehicleDataServer = JSON.parse(JSON.stringify(data));
    },
    emitChange() {
      this.$emit('input', this.items);
    },
    async addproduct() {
      if (this.productDescription.length) {
        let query = {
          optionId: this.currentItem.id,
          type: this.currentItem.type,
          description: this.productDescription,
          explanation: this.productExplanation
        };
        const dest = this.currentItem.dest == 'ECU' ? createProduct : dsgCreateProduct;
        const {data} = await post(dest, query, true);

        if (data.statusCode == '200') {
          let newElement = {
            description: `(NEW) ${this.productDescription}`,
            explanation: this.productExplanation,
            type: this.currentItem.type,
            id: data.id,
          };
          this.currentItem.options.push(newElement);
          this.currentItem.icon = 'check';
          this.currentItem.option_selected = newElement.id;
          const index = this[this.currentItem.location].findIndex(el => el.id == this.currentItem.id);
          this.$set(this[this.currentItem.location], index, this.currentItem);
          await this.selectSecondaryOption(this.currentItem, newElement.id, index);
          this.showNewProduct = false;
          this.currentItem = null;
          await this.showAlert('New product saved', 'success');
          this.$refs.addProduct.hide();
          this.productDescription = '';
          this.productExplanation = '';
        }
      } else {
        this.showAddProductError = true;
      }
    },
    handleClose() {
      this.$refs.addProduct.hide();
      this.resetOption(this.currentItem);
    },
    async showAlert(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: msg,
        icon: (type === 'success') ? 'success' : 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    resetOption(item) {
      item.class = '';
      const index = this.items.findIndex(el => el.id == item.id && el.location == item.location);
      if (index > -1) {
        if (item.required) {
          this.items[index].options = JSON.parse(JSON.stringify(item.options));
        } else {
          this.items.splice(index, 1);
        }
      }
      const indexInArray = this[item.location].findIndex(el => el.id == item.id);
      this[item.location][indexInArray].icon = 'add';
      this.$set(this[item.location], indexInArray, this[item.location][indexInArray]);
      this.$refs[`${item.location}-${item.id}`][0].textSelected = 'Select';
      this.$refs[`${item.location}-${item.id}`][0].setPreselected();
      this.emitChange();
    },
    async showExtraInput(options) {
      const inputs = [];
      options.inputs.forEach(el => {
        inputs.push(`
        <div>${el.label}</div>
        <input class="swal2-input text-uppercase" name="${el.field}" id="${el.field}" maxlength="${el.maxlength}" minlength="${el.minlength}"/>
        `);
      });
      if (options.subtitle) {
        inputs.push(`${options.subtitle}`);
      }
      const value = await this.$swal({
        title: options.title,
        text: options.subtitle,
        showConfirmButton: true,
        showCancelButton: true,
        html: inputs.join('\n'),
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        preConfirm: () => {
          const values = {};
          options.inputs.forEach(el => {
            values[el.field] = document.getElementById(el.field).value;
          });
          let isInvalid = false;
          Object.keys(values)
            .forEach(k => {
              if (values[k] == '') {
                isInvalid = true;
              }
            });
          if (!isInvalid) {
            return values;
          } else {
            this.$swal.showValidationMessage('Enter a value in the field(s)');
          }
        }
      });
      if (value.isConfirmed) {
        return value.value;
      } else {
        return false;
      }
    },
    async validate() {
      const existSoftwareUnselected = this.sofwareMandatory.filter((el) => {
        const itemInCart = this.items.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });
      const existHardwareUnselected = this.hardwareMandatory.filter((el) => {
        const itemInCart = this.items.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });

      const existDsgPreUnselected = this.preSelectedDsgOptions.filter((el) => {
        const itemInCart = this.items.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });

      const dsgHardwareXoptions = this.dsgHardwareXoptions.filter((el) => {
        const itemInCart = this.items.find(item => el.id === item.id && el.location === item.location);
        if (itemInCart) {
          return Array.isArray(itemInCart.options) && itemInCart.options.length > 1;
        } else {
          return false;
        }
      });

      this.sofwareMandatory = this.sofwareMandatory.map(el => {
        return {
          ...el,
          class: existSoftwareUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.hardwareMandatory = this.hardwareMandatory.map(el => {
        return {
          ...el,
          class: existHardwareUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.preSelectedDsgOptions = this.preSelectedDsgOptions.map(el => {
        return {
          ...el,
          class: existDsgPreUnselected.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      this.dsgHardwareXoptions = this.dsgHardwareXoptions.map(el => {
        return {
          ...el,
          class: dsgHardwareXoptions.find(inValid => inValid.id === el.id) ? 'color-red' : ''
        };
      });
      if (existSoftwareUnselected.length || existHardwareUnselected.length || dsgHardwareXoptions.length) {
        await this.$swal({
          title: 'Error',
          text: "Required option(s) weren't selected. Make sure the options are selected and try again.",
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#d14343',
          customClass: {
            container: 'my-swal'
          }
        });
        if (this.$refs.org_file) {
          this.$refs.org_file.setAsError();
        }
        return false;
      } else {
        return true;
      }
    },
    ready(item) {
      let index = this.items.findIndex(el => el.id === item.id);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
      this.items.push(item);
      this.emitChange();
    }
  },
  computed: {
    ...mapGetters('geo', ['userCountryPrice', 'currencyToUser']),
    ...mapGetters('geo', ['currencyToUser']),
    ...mapGetters('orderSoftwareFile', ['DsgConversionAndFlexStatus']),
    isEdited() {
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/views/dashboard/create-software-order/options/index';
</style>
