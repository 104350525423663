export default {
  methods: {
    async showAlertNotification(msg, type = 'success', show_cancel_button = false) {
      const titles = {
        success: 'Success',
        error: 'Error',
        info: 'Information',
        warning: 'Warning'
      }
      await this.$swal({
        title: titles[type],
        html: msg,
        icon: (type === 'success') ? 'success' : type,
      showCancelButton: show_cancel_button,
       showConfirmButton: true,
       cancelButtonText: `Cancel`,
       confirmButtonText: `OK`,
       confirmButtonColor: '#d14343',
       cancelButtonColor: '#000000',
      });
    }
  }
}
