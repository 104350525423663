<template>
  <b-dropdown :disabled="disabled" :text="textShowed" class="custom-drop" size="sm" toggle-class="btn-custom"
              variant="ligth" no-caret>
    <template v-for="(item, index) of items">
      <template v-if="item.items">
        <b-dropdown-group :header="item.label">
          <b-dropdown-item-btn v-for="(subitem, index) of item.items" :class="item.class" :key="index"
                               @click="onChange(subitem)">{{
              subitem.description
            }}
          </b-dropdown-item-btn>
        </b-dropdown-group>
      </template>
      <b-dropdown-item-btn v-else :class="item.class" :key="index" @click="onChange(item)">{{
          item.description
        }}
      </b-dropdown-item-btn>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: 'DropDownSelect',
  props: {
    value: String,
    items: Array,
    defaultText: {
      type: String,
      default: 'Select'
    },
    disabled: {
      default: false
    },
    preselectedOptions: {
      default: {
        emitNull: true,
        values: []
      },
    },
  },
  data: function () {
    return {
      textSelected: this.defaultText,
    };
  },
  computed: {
    textShowed: function () {
      return this.textSelected.length >= 16 ? this.textSelected.slice(0, 16) + '...' : this.textSelected;
    }
  },
  methods: {
    onChange(item) {
      if (this.preselectedOptions.emitNull && this.preselectedOptions.values && this.preselectedOptions.values.includes(item.id)) {
        this.$emit('change', null);
      } else {
        this.$emit('change', item.id);
      }
      this.textSelected = item.description;
    },
    fromValue() {
      if (this.value) {
        this.items.forEach(el => {
          if (el.items) {
            el.items.forEach(item => {
              if (item.id == this.value) {
                this.textSelected = item.description;
                return false;
              }
            });
          } else {
            if (el.id == this.value) {
              this.textSelected = el.description;
              return false;
            }
          }
        });
      } else {
        this.textSelected = this.defaultText;
      }
    },
    setPreselected() {
      if (this.preselectedOptions.values && Array.isArray(this.preselectedOptions.values) && this.preselectedOptions.values.length) {
        this.items.forEach(el => {
          if (el.items) {
            el.items.forEach(item => {
              if (this.preselectedOptions.values.includes(item.id)) {
                this.textSelected = item.description;
                return false;
              }
            });
          } else {
            if (this.preselectedOptions.values.includes(el.id)) {
              this.textSelected = el.description;
              return false;
            }
          }
        });
      } else {
        this.textSelected = this.defaultText;
      }
    }
  },
  mounted() {
    this.fromValue();
    if (!this.value) {
      this.setPreselected();
    }
  },
  watch: {
    value: function () {
      this.fromValue();
    },
  }
};
</script>

<style lang="scss">
.custom-drop {
  margin-top: 3px;

  .btn-custom {
    width: 100%;
    overflow: hidden;
    border-color: #B1B1B1 !important;
    //border-width: 0;
    border-radius: 4px;
    height: 25px;
    color: #5A6169;
    font: 300 15px 'roboto';
    padding: 0.25rem 0.875rem 0.4286rem 0;
    line-height: 1;
    //display: flex;
    text-align: right;

    &:active {
      color: #0b0b0b !important;
      box-shadow: none !important;
    }

    &:hover, &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      border: 1px solid #b1b1b1;
    }

    &:after {
      font-family: "Material Icons";
      content: "\e5cf";
      margin-left: 5px;
      border: none;
      vertical-align: middle;
      position: absolute;
      right: 0;
      font-weight: lighter;
    }
  }

  .dropdown-menu {
    padding: 0;
    max-height: 350px;
    overflow-y: scroll;
    border: 1px solid #B1B1B1;
    border-radius: 0;
  }

  .dropdown-item {
    padding: 0.01rem .5rem;
    border-bottom: 1px solid #efefef;
    text-align: right;

    &:hover {
      background: #0b0b0b;
      color: #cccccc;
    }
  }

  .dropdown-header {
    font-size: 16px;
    font-weight: 500;
    color: #0b0b0b;
    border-bottom: 1px solid #efefef;
    padding: 0 .5rem;
  }
}
</style>
